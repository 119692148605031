body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.answerDiv {
}

.answerDiv:hover:not(.showAnswer) .panel-heading{
  background-color: #48c774;
  color: #fff;
}

.answerDiv:hover:not(.showAnswer) .choiceInfo{
  display: block !important;
}

.answerDiv:hover:not(.showAnswer){
  cursor: pointer;
}

.panel-heading  {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


img.productInfo {
  max-width:100%;
  max-height:100%;
  height: auto;
  width:auto;
  margin:auto;
}

.h-100 {
  height: 100% !important; 
}

.w-100 {
  width: 100% !important; 
}

.hero-body {
  padding: 0 !important;
}

.is-a .panel-heading, span.is-a{
  background-color: #48c774;
  color: #fff;
}

.is-b .panel-heading, span.is-b{
  background-color: #0b54249a;
  color: #fff;
}

.is-c .panel-heading, span.is-c{
  background-color: hsl(48, 100%, 67%);
  color: rgba(0, 0, 0, 0.7);
}

.is-d .panel-heading, span.is-d{
  background-color: #ff9933;
  color: #fff;
}

.is-e .panel-heading, span.is-e{
  background-color: hsl(348, 100%, 61%);
  color: #fff;
}

.breadcrumb.has-arrow-separator li + li::before {
  padding-right: 0.75em;
}

.shareLinkName::placeholder, .shareLinkUrl::placeholder {
  font-size: 0.85em;
}

.loading-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
}

.loading-overlay.is-active {
  display: flex;
}

.loading-overlay .loading-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #7f7f7f;
  background: hsla(0,0%,100%,.5);
}

.loading-overlay .loading-icon {
  position: relative;
}

.loading-overlay .loading-icon:after {
  animation: spinAround .5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  position: absolute;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
  width: 3em;
  height: 3em;
  border-width: .25em;

}

.container .columns {
  margin: 0
}

@media screen and (max-width:768px) {
  .titleTips {
    font-size: 1rem !important;
    margin-bottom: 0px !important;
  }
  .panelImage {
    height: 18vh !important;
  }
  .answerTip {
    font-size: 1.3rem !important;
  }
  .answerDiv .panel-heading{
    font-size: 1rem !important;
  }

  .breadcrumb.has-arrow-separator li+li::before {
    display: none;
  }

  .scoreTitle {
    font-size: 1.5rem !important;
  }
 }



